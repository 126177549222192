//Colors Backgrouds
$background-white:#FCFCFC;
$background-pure: #FFFFFF;

//Grays
$gray-medium-stone:#6C757D;
$gray-semi-stone:#A0AEC0;
$gray-light-stone:#CED4DA;
$gray-borders:#EDF2F7;

//Color Branding
$blue-brand: #2F80C2;

//Principal Colors
$orange-tangerine:#FF7D57;
$blue-lagoon:#4D7EFF;

//Colors text
$text-dark-river:#0A0633;
$text-dark-sky:#1B2149;
$text-white:#FFFFFF;
$text-black:#27272E;

//Colors states
$state-red:#F16063;
$state-red-light:#FF6669;
$state-success:#5ECC85;
$state-success-light:#6AE596;
$state-warning:#F2B946;
$state-warning-light:#FABF48;
$state-info: #4D7EFF;

//Colors Button
$button-default:#4D7EFF;
$button-hover:#225DF4;
$button-pressed:#83A6FF;
$button-disabled:#CED4DA;
$button-danger-hover:#F34044;
$button-danger-pressed:#F87C7F;
$button-secondary-hover: #544CCC;
$button-secondary-active: #9892FC;
