@import "../../Styles/components/colors";

.loading-page-container {
  background-color: $background-white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 60.1906px;
    line-height: 75px;
    letter-spacing: -0.415697px;
    color: #16296B;
    margin-left: 16px;
  }
}