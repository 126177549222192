@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import "./components/colors";
@import "./components/variables";
@import "./reactBoostrapStyles/table";
@import "./reactBoostrapStyles/button";
@import "react-quill/dist/quill.snow.css";
@import 'react-big-calendar/lib/sass/styles';
@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


body {
  margin: 0;
  font-family: 'DM Sans', sans-serif, 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-white;
}

html, main {
  scroll-behavior: smooth;
}

a {
  color: initial;
  text-decoration: none;
  &:hover {
    color: initial
  }
}
p {
  margin-bottom: 0;
}

.container {
  max-width: 1600px;
  padding: 32px 60px;
  @media (max-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.btn-close {
  width: 15px;
  height: 15px;
  padding: 0;
  background-image:  url(../../public/assets/svg/close.svg)  !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  

