@import '../../Styles/components/colors';

.toast-custom {
  display: flex;
  align-items: center;
  min-width: 431px;
  min-height: 80px;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 0px 1px rgba(108, 117, 125, 0.1), 0px 4px 20px -2px rgba(39, 39, 46, 0.08);
  background-color: transparent;
  border: initial;
  &.toast-custom-background-success{
    background-color: $state-success-light;
  }
  &.toast-custom-background-warning{
    background-color: $state-warning-light;
  }
  &.toast-custom-background-error{
    background-color: $state-red-light;
  }

  .toast-custom-header{
    width: 100%;
    border: none;
    color: $text-black;
    padding: 0px;
    background-color: transparent;
    &.toast-header-background-success{
      background-color: $state-success-light;
    }
    &.toast-header-background-warning{
      background-color: $state-warning-light;
    }
    &.toast-header-background-error{
      background-color: $state-red-light;
    }


  .toast-custom-icon {
    border-radius: 50px;
    padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    margin-right: 10px;
    color: $text-black;
    background-color:transparent;
    &.toast-icon-background-success{
      background-color:#64D98D;
    }
    &.toast-icon-background-warning{
      background-color:#FF9432;
    }
    &.toast-icon-background-error{
      background-color:#F16063;
    }
  }

  .toast-custom-message {
    width: 90%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $text-black;
    p {
      margin-bottom: 0;
    }
  }

  .toast-custom-button-close {
    border: none;
    background: transparent;
  }
}
}