@import "../../Styles/components/colors";
.circle-white {
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #edeef0;
  position: relative;
  
  .circle-orange {
    border-radius: 100%;
    background: $blue-brand;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    &.animation {
      animation: changecircle 2s ease-in-out infinite;
    }
    
    .triangle {
      width: 0;
      height: 0;
      border-top-style: solid;
      border-right-style: solid;
      border-left-style: solid;
      border-top-color: white;
      border-right-color: transparent;
      border-left-color: transparent;
      position: relative;
      z-index: 10;
      transform: translate(0, 10%);
      &.animation {
        animation: changetriangle 2s ease-in-out infinite;
      }
     
      @keyframes changetriangle {
        0% {
          border-top-color: $blue-brand;
         
        }
        50% {
          border-top-color: white;
        }
        100% {
          border-top-color: $blue-brand;
        }
      }
    }
    @keyframes changecircle {
      0% {
        background-color: white;
       
      }
      50% {
        background-color: $blue-brand;
      }
      100% {
        background-color: white;
      }
    }
  }
 
}
