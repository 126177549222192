@import "../components/colors";

.btn.btn-primary {
  background-color: $blue-lagoon;
  border: initial;
  border-radius: 80px;
  box-shadow: initial;
  padding: 10px 24px;
  font-weight: bold;
  &:hover,
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: $button-hover !important;
  }
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
    background-color:  #9892FC !important;
   
  }
}

.btn.btn-outline-primary {
  border-color:$blue-lagoon;
  color: $blue-lagoon;
  border-radius: 80px;
  font-weight: bold;
  &:hover,
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-color:$button-hover;
    color: $button-hover;
    border-radius: 80px;
  }
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
    background-color:  transparent !important;
    border-color:#9892FC;
    color: #9892FC;
    border-radius: 80px;
   
  }
}

.btn.btn-outline-danger {
  border-color:$state-red;
  color: $state-red;
  border-radius: 80px;
  &:hover,
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: $button-danger-hover;
    color: $button-danger-hover;
    border-radius: 80px;
  }
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: $button-danger-pressed;
    color: $button-danger-pressed;
    border-radius: 80px;
  }
}