@import '../components/colors.scss';

.table {
  --bs-table-hover-bg: #FAFAFA;
  border-collapse:separate;
  border-spacing:0;
  margin-bottom: 0;
}

.table>thead{
  background-color: $gray-borders;
  border: transparent;
}

.table > :not(caption) > * > * {
  padding: 1rem 60px;
}

.table > :not(:first-child) {
  border-top: initial;
}

tbody tr {
  background-color:#FFFFFF;
  cursor: pointer;
  &:hover {
    background-color:#FAFAFA;
  }
}
th {
  color: $text-dark-sky;
}
td {
  vertical-align: middle;
  background-color:#FFFFFF;
  color: $text-dark-river;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



tbody tr:last-child td{
  border-bottom-color: transparent;
}


tbody tr:last-child td:first-child {
  border-bottom-left-radius: 14px;
}
tbody tr:last-child td:last-child {
  border-bottom-right-radius: 14px;
}

